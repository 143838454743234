<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Kontakt oss
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            <strong>Besøksadresse:</strong> Christian Krohgs gate 16, 0186 OSLO
          </p>
          <p>
            <strong>Postadresse:</strong> Postboks 1182 Sentrum, NO-0107 OSLO
          </p>
          <p>
            <strong>Telefon:</strong> +47 22 00 11 50
          </p>
          <p>
            <strong>Redaksjonelt:</strong> <a href="mailto:news@europower.no">news@europower.no</a><br>
            <strong>Abonnement:</strong> <a href="mailto:abo@europower.no">abo@europower.no</a><br>
            <strong>Annonse:</strong> <a href="mailto:marked@europower.no">marked@europower.no</a>
          </p>
          <p>
            Europower vil gjerne høre meninger og reaksjoner fra sine lesere. Brev eller e-post til redaksjonen må inneholde navn, adresse og telefonnummer.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'contactus-page',
  extends: Page
};
</script>
